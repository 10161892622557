import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import { Form, Row, Col, Alert, Button, InputGroup, FormControl, Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import getData from '../params/getdata'
import { faArrowRight, faArrowDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import '../style/guest.css'
import '../style/table.scss'
import MediaQuery from 'react-responsive'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { pw } from '../params';
library.add(faArrowRight, faArrowDown, faSearch);
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.nama = React.createRef()
        this.state = {
            dataawal: [],
            datatampil: [],
            search: null,
            clicked: 'x',
            jumlah_hadir: [],
            jumlah_tidak_hadir: [],
        }
    }
    async componentDidMount() {
        try {
        } catch (e) {
            console.log(e)
        }
    }
    handleSubmit = async () => {
        this.setState({ isLoading: true })
        let nama = this.nama.current.value
        let q = `${nama.toLowerCase()}`
        if (nama == "admin-alldata") {
            q = ''
        }
        let data = await getData(q)
        console.log(data)
        this.setState({ datatampil: data, search: true, isLoading: false })
    }
    render() {
        let { search, datatampil, clicked, isLoading } = this.state
        let jumlah_hadir = datatampil.filter(v => {
            return v.hadir != "false" && !v.hadir.includes("Can't") && !v.hadir.includes("No") && !v.hadir.includes("Tidak") && !v.hadir.includes("Declines") && !v.hadir.includes("tidak") && !v.hadir.includes("Sorry")
        }).length
        let jumlah_tidak_hadir = datatampil.length - jumlah_hadir
        console.log(datatampil)
        return (
            <>
                <div id='guest'>
                    <Container >
                        <h1>
                            Buku Tamu
                        </h1>
                        <InputGroup className="mb-3">
                            <FormControl ref={this.nama}
                                placeholder="Mempelai"
                                aria-label="Mempelai"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <Button onClick={() => { this.handleSubmit() }}
                                    variant="outline-secondary" className='m-2 bg-main'><FontAwesomeIcon icon={faSearch} className='mt-auto mb-auto' /></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        {
                            isLoading ? (
                                <>
                                    <Container>
                                        <div>
                                            <Col>
                                                <Spinner animation="border" />
                                            </Col>
                                            <Col xs={12} md={12}>
                                                <img src={pw("asset", "loading.png")} />
                                            </Col>
                                        </div>
                                    </Container>
                                </>
                            ) : (
                                    <div className='hasil'>
                                        {   
                                            search == false ? (
                                                <Alert variant='light'>
                                                    Wah sepertinya ada yang salah, coba lagi!
                                                </Alert>
                                            ) : (search == true ? (
                                                <>

                                                    <Row className="px-3">
                                                        <Col>
                                                            <p classname="mb-0 px-4 text-center">
                                                                Total Undangan : <br />
                                                                <b>{datatampil.length}</b>
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <p classname="mb-0 px-4 text-center">
                                                                Undangan Hadir: <br />
                                                                <b>{jumlah_hadir}</b>
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <p classname="mb-0 px-4 text-center">
                                                                Undangan Tidak Hadir: <br />
                                                                <b>{jumlah_tidak_hadir}</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="px-3">
                                                        <ReactHTMLTableToExcel
                                                            id="test-table-xls-button"
                                                            className="download-table-xls-button btn btn-success my-2"
                                                            table="table-to-xls"
                                                            filename="undangankuDOTme"
                                                            sheet="tablexls"
                                                            buttonText="Download as XLS" />

                                                    </Row>

                                                    < table id="table-to-xls">

                                                        <thead>
                                                            <tr>
                                                                <th >No</th>
                                                                <th >Kepada</th>
                                                                <th>Dari</th>
                                                                <th >Alamat</th>
                                                                <th >Hadir</th>
                                                                <th >Jumlah Hadir</th>
                                                                <th >Pesan</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                datatampil.map((val, i) => {
                                                                    return (
                                                                        <>
                                                                            <MediaQuery minDeviceWidth={900}><tr>
                                                                                <td data-head='No'>{i + 1}</td>
                                                                                <td data-head='Kepada'>{val.kepada}</td>
                                                                                <td data-head='Dari'>{decodeURI(val.dari)}</td>
                                                                                <td data-head='Alamat'>{decodeURI(val.alamat)}</td>
                                                                                <td data-head='Hadir'>{decodeURI(val.hadir)}</td>
                                                                                <td data-head='With'>{val.jumlahhadir}</td>
                                                                                <td data-head='Pesan'>{decodeURI(val.pesan)}</td>
                                                                            </tr>
                                                                            </MediaQuery>
                                                                            <MediaQuery maxDeviceWidth={900}>
                                                                                <tr>
                                                                                    <td data-head='No' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{i + 1}</td>
                                                                                    <td data-head='Kepada' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{val.kepada}</td>
                                                                                    <td data-head='Dari'
                                                                                        onClick={() => {
                                                                                            if (clicked == 1) {
                                                                                                this.setState({ clicked: -1 })
                                                                                            } else {
                                                                                                this.setState({ clicked: i })
                                                                                            }
                                                                                        }}
                                                                                    >{decodeURI(val.dari)}</td>
                                                                                    <td data-head='Alamat' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{decodeURI(val.alamat)}</td>
                                                                                    <td data-head='Hadir' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{decodeURI(val.hadir)}</td>
                                                                                    <td data-head='With' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{decodeURI(val.jumlahhadir)}</td>
                                                                                    <td data-head='Pesan' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{decodeURI(val.pesan)}</td>
                                                                                </tr>
                                                                            </MediaQuery>
                                                                        </>)
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </>) : (false))
                                        }
                                    </div>
                                )
                        }
                    </Container>
                </div>
            </>
        )
    }
}
